import React, {useEffect} from 'react';
// import { Link, graphql } from "gatsby"
// import Img from "gatsby-image"
import Layout from "../components/layout"
// import "../css/index.scss"
import styled from 'styled-components';
import gsap from 'gsap'

import Map from 'pigeon-maps'
import Marker from 'pigeon-marker'
// import Overlay from 'pigeon-overlay'

import Construct from "../images/construct.png";

const TopSection = styled.div`
  height: 20rem;
  background-color: ${props => props.theme.colors.dark};
`

const About = styled.div`
  height: 50rem;
  width: 100%;
  background-color: #F6F6F6;
  padding-top: 10rem;

  img {
    display: block;
    width: 50rem;
    /* object-fit: cover;
    height: 100%; */
    margin: 0 auto;
  }

  @media  ${props => props.theme.breakpoints.down('xs')} {
    img {
      width: 40rem;
    }
  }
`

const MapContainer = styled.div`
  width: 100%;
  height: 45rem;
  border-top: 1px solid ${props => props.theme.colors.primary};
  /* padding: 1rem; */
`

const AboutPage = ({ data }) => {

  useEffect(() => {
    gsap.to('.logo', {autoAlpha: 1});
  }, [])

  const redirect = (location) => {
    window.location.href = location
  }

  return (
    <>
  <Layout>
    <TopSection />
    <About>
      <img src={Construct} />
    </About>

    <MapContainer>
      <Map provider={(x, y, z, dpr) => { 
        return `https://a.tile.openstreetmap.fr/hot/${z}/${x}/${y}.png`}} 
      center={[41.057320, -74.140976]} zoom={9.2} defaultWidth={2000}
      // onClick={() => {
      //   redirect()
      // }}
      >
        
        <Marker anchor={[40.698081, -73.961042]} payload={1} onClick={({ e, anchor, payload }) => {
         redirect("https://google.com/maps/place/32+Taaffe+Pl/@40.698081,-73.9632307,17z/data=!4m6!3m5!1s0x89c25bc222042c5b:0xd40a20f59a8938fd!4b1!8m2!3d40.6979345!4d-73.9610639")
        }} />

        <Marker anchor={[41.333566, -74.162619]} payload={1} onClick={({ e, anchor, payload }) => {
         redirect("https://www.google.com/maps/place/DW+DESIGN/@41.3337577,-74.1634936,18z/data=!3m1!4b1!4m13!1m7!3m6!1s0x89c2d6fdac9f8345:0xa0de529e37a838dd!2s1+Teverya+Way,+Kiryas+Joel,+NY+10950!3b1!8m2!3d41.3334697!4d-74.1625763!3m4!1s0x89c2d79120d4346f:0xee5840010c5f0a5b!8m2!3d41.3337557!4d-74.1623993")
        }} />

      {/* <Overlay anchor={[50.879, 4.6997]} offset={[120, 79]}>
        <img src='pigeon.jpg' width={240} height={158} alt='' />
      </Overlay> */}
    </Map>
    </MapContainer>

  </Layout>
  </>
)
}

export default AboutPage;

// export const pageQuery = graphql`
//   query IndexQuery {
//     strapiHero {
//       Heading1
//       Heading2
//       HeroImage {
//         childImageSharp {
//           fluid(maxWidth: 3000) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
//   `